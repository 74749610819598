var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "tabs1" },
        _vm._l(_vm.tabs, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "tab_item",
              class: index == _vm.current ? "tab_item_active" : "",
              on: {
                click: function ($event) {
                  return _vm.changeCurrent(index)
                },
              },
            },
            [_vm._v(" " + _vm._s(item) + " ")]
          )
        }),
        0
      ),
      _vm.current == 0 ? _c("arrearageSummationTotal") : _vm._e(),
      _vm.current == 1 ? _c("arrearageParkingList") : _vm._e(),
      _vm.current == 2 ? _c("arrearagePlateList") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }