var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "circularChart", attrs: { id: _vm.type } }),
      _vm._t("default", null, { money: _vm.money }),
      _vm._t("default", null, { strip: _vm.strip }),
      _vm._t("default", null, { totalDegree: _vm.totalDegree }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }