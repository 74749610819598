<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.region')" prop="areaId" label-width="50px">
                <a-cascader ref="cascader" @change="parkClear"></a-cascader>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.region')" prop="areaId">
          <el-select
            v-model.trim="formInline.areaId"
            @change="areaChange"
            filterable
            size="15"
            placeholder="请选择"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              :label="area.areaName"
              :value="area.areaId"
              :key="area.areaId"
              v-for="area in areaList"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.region')" prop="streetId">
          <el-select v-model.trim="formInline.streetId" filterable size="15" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option
              :label="area.areaName"
              :value="area.areaId"
              :key="area.areaId"
              v-for="area in areaList2"
            ></el-option>
          </el-select>
        </el-form-item> -->
              <el-form-item :label="$t('searchModule.charge_type')" prop="scopeId" label-width="80px">
                <el-select v-model.trim="formInline.scopeId" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="一类区" value="1"></el-option>
                  <el-option label="二类区" value="2"></el-option>
                  <el-option label="三类区" value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.park_name')">
                <a-park-select
                  ref="parkSelect"
                  :instance="this"
                  parkTypeRefName="parkTypeSelect"
                  operationRefName="operationSelect"
                  areaRefName="cascader"
                ></a-park-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Vehicle_type')" label-width="80px">
                <el-select filterable v-model.trim="formInline.plateNumberType">
                  <el-option label="全部" value="0"></el-option>
                  <el-option label="不含无牌车" value="1"></el-option>
                  <el-option label="无牌车" value="2"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchData()"
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="clearData('formInline')"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!-- <div class="chearts-wrapper">
      <div style="width:100%" class="grid-content bg-purple graphShadow paddingT10 paddingL20 paddingB10">
        <graphTitle :title="'欠费金额占比图'">
          <el-tooltip placement="top" content="展示历史全部的欠费金额与实收金额占总应收金额的比例">
            <i class="el-icon-question my-icon" />
          </el-tooltip>
        </graphTitle>
        <CircularChart :arrearageMoney="arrearageMoney" :type="type1" style="width: 100%">
          <template slot-scope="scores">
            <h2 style="text-align: center; font-size: 18px">{{ scores.money }}</h2>
          </template>
        </CircularChart>
      </div>
      <div style="width:100%" class="grid-content bg-purple graphShadow paddingT10 paddingL20 paddingB10">
        <graphTitle :title="'欠费条数占比图'">
          <el-tooltip
            placement="top"
            content="展示历史全部的欠费订单数与已支付订单数占总订单数的比例"
          >
            <i class="el-icon-question my-icon" />
          </el-tooltip>
        </graphTitle>
        <CircularChart :arrearageStrip="arrearageStrip" :type="type2">
          <template slot-scope="scores">
            <h2 style="text-align: center; font-size: 18px">{{ scores.strip }}</h2>
          </template>
        </CircularChart>
      </div>
      <div style="width:100%" class="graphShadow paddingT10 paddingLR20">
        <graphTitle :title="'排行榜'"></graphTitle>
        <rankingList
          :arrearageParking="arrearageParking"
          :arrearagePlate="arrearagePlate"
        ></rankingList>
      </div>
    </div> -->
      <div style="margin: 16px 0 0 4px">最近统计时刻: {{ lastCountTime }}</div>
      <el-row :gutter="20" class="list marginTB20">
        <el-col :span="8">
          <div class="grid-content bg-purple graphShadow paddingT10 paddingL20 paddingB10">
            <graphTitle :title="'欠费金额占比图'">
              <el-tooltip placement="top">
                <div
                  slot="content"
                  v-html="
                    toBreak(
                      '展示历史全部的欠费金额与实收金额占总应收金额的比例。\n实收金额=优惠金额+实付金额-退款金额'
                    )
                  "
                ></div>
                <i class="el-icon-question my-icon" />
              </el-tooltip>
            </graphTitle>
            <CircularChart :arrearageMoney="arrearageMoney" :type="type1" style="width: 100%">
              <template slot-scope="scores">
                <h2 style="text-align: center; font-size: 18px">{{ scores.money }}</h2>
              </template>
            </CircularChart>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple graphShadow paddingT10 paddingL20 paddingB10">
            <graphTitle :title="'欠费条数占比图'">
              <el-tooltip
                placement="top"
                content="展示历史全部的欠费订单数与已支付订单数占总订单数的比例。此处统计的订单为已出场停车订单。"
              >
                <i class="el-icon-question my-icon" />
              </el-tooltip>
            </graphTitle>
            <CircularChart :arrearageStrip="arrearageStrip" :type="type2">
              <template slot-scope="scores">
                <h2 style="text-align: center; font-size: 18px">{{ scores.strip }}</h2>
              </template>
            </CircularChart>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="graphShadow paddingT10 paddingLR20">
            <graphTitle :title="'排行榜'"></graphTitle>
            <rankingList
              :arrearageParking="arrearageParking"
              :arrearagePlate="arrearagePlate"
              :showDetai="false"
            ></rankingList>
          </div>
        </el-col>
        <el-col :span="24">
          <CircularChartFold
            :arrearageSummationtableData="arrearageSummationtableData"
            :lastCountTime="lastCountTime"
            :type="type2"
            class="graphShadow paddingT10 paddingL20 marginTB20"
          ></CircularChartFold>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// 折线行图arrearageSummationCircularChart.vue
// import CircularChartFold from '../../../components/circularChart/circularChart4';
import CircularChartFold from "./arrearageSummationCircularChart";
// 饼形图
import CircularChart from "./circularChartNew";
// 框列表
import rankingList from "@/components/rankingList/rankingList";
// 车场名称
import myComponent from "@/components/autocomplete/myautoComponent";
import graphTitle from "@/components/graphTitle";

export default {
  name: "arrearageSummation",
  components: {
    CircularChartFold,
    CircularChart,
    rankingList,
    myComponent,
    graphTitle,
  },
  data() {
    return {
      type1: "c1",
      type2: "c2",
      loading: false,
      // 计费类型
      billingTypes: [],
      tableData: [],
      // 区域列表
      areaList: [],
      // 区域街道
      areaList2: [],
      // 欠费金额占比图
      arrearageMoney: [],
      // 欠费条数占比图
      arrearageStrip: [],
      // 欠费趋势图
      arrearageSummationtableData: [],
      lastCountTime: "", // 欠费趋势最近统计时刻
      // 欠费车场排名
      arrearageParking: [],
      // 欠费车牌排名
      arrearagePlate: [],
      formInline: {
        areaId: "",
        streetId: "",
        scopeId: "",
        // 车场ID
        parkId: "",
        // 车场名称
        parkName: "",
      },
    };
  },
  mounted() {
    // var chartDom = document.getElementById("main");
    // var myChart = this.$echarts.init(chartDom);
    // let option = {
    //   tooltip: {
    //     trigger: "item",
    //   },
    //   legend: {
    //     top: "5%",
    //     left: "center",
    //   },
    //   series: [
    //     {
    //       name: "Access From",
    //       type: "pie",
    //       radius: ["40%", "70%"],
    //       avoidLabelOverlap: false,
    //       label: {
    //         show: false,
    //         position: "center",
    //       },
    //       emphasis: {
    //         label: {
    //           show: true,
    //           fontSize: "40",
    //           fontWeight: "bold",
    //         },
    //       },
    //       labelLine: {
    //         show: false,
    //       },
    //       data: [
    //         { value: 1048, name: "Search Engine" },
    //         { value: 735, name: "Direct" },
    //         { value: 580, name: "Email" },
    //         { value: 484, name: "Union Ads" },
    //         { value: 300, name: "Video Ads" },
    //       ],
    //     },
    //   ],
    // };
    // option && myChart.setOption(option);
  },
  methods: {
    toBreak(val) {
      return val.split("\n").join("<br/>");
    },

    parkClear() {
      this.$refs.parkSelect.clear();
    },
    // 清空
    clearData(formname) {
      this.$refs[formname].resetFields();
      // this.$refs.parkInput.modelvalue = "";
      this.$refs.cascader.clear();
      this.$refs.parkSelect.clear();
      this.formInline.plateNumberType = "";
      this.searchData();
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    areaChange(item) {
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    // 初始化用户区域下拉列表（区域和街道）
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    // 计费类型
    billingFn() {
      this.$axios.get("/acb/2.0/systems/loginUser/initScopeList").then((res) => {
        this.billingTypes = res.value.scopeList;
      });
    },
    // 查询
    searchData() {
      // // 欠费金额占比图
      // this.arrearageMoney = [];
      // // 欠费条数占比图
      // this.arrearageStrip = [];
      // // 欠费金额趋势
      // this.arrearageSummationtableData = [];
      // // 欠费车场排名
      // this.arrearageParking = [];
      // // 欠费车牌排名
      // this.arrearagePlate = [];
      // console.log(this.$refs.cascader);
      // console.log(this.$refs.cascader ? this.$refs.cascader.getAreaId() : "", "getAreaId");
      // console.log(this.$refs.cascader ? this.$refs.cascader.getStreetId() : "", "getStreetId");
      this.$axios
        .get("/acb/2.0/debtStatistics/searchDebtOrders", {
          data: {
            streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            scopeId: this.formInline.scopeId,
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
            plateNumberType: this.formInline.plateNumberType,
          },
        })
        .then((res) => {
          // 欠费金额占比图
          this.arrearageMoney = [];
          // 欠费条数占比图
          this.arrearageStrip = [];
          // 欠费金额趋势
          this.arrearageSummationtableData = [];
          // 欠费车场排名
          this.arrearageParking = [];
          // 欠费车牌排名
          this.arrearagePlate = [];
          // 欠费金额占比图
          this.arrearageMoney.push(res.value.arrearagestatics);
          // 欠费条数占比图
          this.arrearageStrip.push(res.value.arrearagestatics);
          // 欠费金额趋势
          this.arrearageSummationtableData = res.value.arrearagestatics.arrearageTrend;
          this.lastCountTime = res.value.time;
          // 欠费车场排名
          this.arrearageParking = res.value.arrearageparkrank;
          // 欠费车牌排名
          this.arrearagePlate = res.value.arrearageplaterank;
        });
    },
  },
  created() {
    // 初始化用户区域下拉列表（区域和街道）
    this.getArea();
    // 计费类型
    // this.billingFn();
    // 查询
    this.searchData();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.chearts-wrapper
  display: flex;
  flex-direction: row;
  justify-content: space-between;
</style>
